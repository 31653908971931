import { ThunkAction } from "redux-thunk"
import * as types from "./types"
import { RootState } from "../store"
import { VERSION } from "../../services/PlayService"
import {
  LevelAndPointsService,
  PointsStepEntity,
} from "../../services/LevelAndPointsService"
import { actions } from "../actions"
import dayjs from "dayjs"
import {
  HEARTS_ONE_DAY_POWER_UP,
  SUBSCRIPTION_ITEM_ID,
} from "../../database/marketplace-items"
import LEVELS from "../../constants/levels.json"

export const $saveGame =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, play } = getState()

    const game = play.actual

    if (!game || play.win) return

    di.PlayRepository.save(`sudoku/playing/${game.getGame().difficulty}`, game)
  }

export const $saveStatsIfWin =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, play, points, auth } = getState()

    if (!play.win || !play.actual) return

    const stats = {
      date: new Date(),
      clues: play.actual.clues,
      duration: play.actual.duration,
      errors: Math.max(play.actual.getNumberErrors(), 0),
      id: new Date().toISOString(),
      level: play.actual.getGame().difficulty,
    }

    di.PlayRepository.remove(`sudoku/playing/${stats.level}`)

    dispatcher(storeStatsAndCreateShareableLink(stats))

    const bonus: PointsStepEntity[] = []

    const levelAndPoints = LevelAndPointsService.apply({
      actualPoints: points.points,
      stats,
      bonus,
    })

    dispatcher(
      actions.points.store({
        level: levelAndPoints.level,
        nextLevelPoints: LevelAndPointsService.getNextLevel(
          levelAndPoints.level,
        ),
        points: levelAndPoints.totalPoints,
        steps: levelAndPoints.steps,
      }),
    )

    if (stats.clues === 0 && stats.errors === 0) {
      dispatcher(
        actions.achievements.$incrementAll({
          parent: `stars__${stats.level}`,
        }),
      )
    }

    if (levelAndPoints.levelUp) {
      dispatcher(
        actions.achievements.$setToAll({
          parent: `level`,
          value: levelAndPoints.level,
        }),
      )
    }

    if (auth.user?.id) {
      di.StatsRepository.store({ stats, userId: auth.user.id })
      di.PointsRepository.store({
        points: levelAndPoints.pointsEarned,
        userId: auth.user.id,
        avatar: auth.user.avatar,
        username: auth.user.username,
      })
      di.LeaderboardRepository.storePoints({
        points: levelAndPoints.pointsEarned,
        userId: auth.user.id,
        avatar: auth.user.avatar,
        username: auth.user.username,
      })

      dispatcher(actions.points.$fetchLeaderboard())
      dispatcher(actions.daily.$create_new_streak())
    }

    if (stats.clues === 0 && stats.errors === 0) {
      di.DailyRepository.storeDailyResult({
        duration: stats.duration,
        dailyId: dayjs().format("YYYY-MM-DD") + ":" + stats.level,
        userId: auth.user?.id || "anonymous",
        level: stats.level,
      })
    }

    di.AnalyticsService.send({
      category: "play",
      action: "finish",
      data: {
        level: stats.level,
        errors: stats.errors,
        hints: stats.clues,
        duration: stats.duration,
      },
    })
  }

export const selectGame = (
  payload: types.SelectGameAction["payload"],
): types.PlayActionTypes => ({
  type: types.SelectGame,
  payload,
})

export const storeStatsAndCreateShareableLink = (
  payload: types.StoreStatsAndCreateShareableLinkAction["payload"],
): types.PlayActionTypes => ({
  type: types.StoreStatsAndCreateShareableLink,
  payload,
})

export const $selectGame =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, marketplace, global, pricing } = getState()

    const lifePowerUp =
      marketplace.items.has(HEARTS_ONE_DAY_POWER_UP) || pricing.isPremium

    dispatcher(
      selectGame({
        lifePowerUp,
      }),
    )

    dispatcher($saveGame())
    dispatcher(actions.global.$refreshAds())
  }

export const selectCel = (
  payload: types.SelectCelAction["payload"],
): types.PlayActionTypes => ({
  type: types.SelectCel,
  payload,
})

export const nextChoose = (): types.PlayActionTypes => ({
  type: types.NextChoose,
})

export const previousChoose = (): types.PlayActionTypes => ({
  type: types.PreviousChoose,
})

export const reset = (): types.PlayActionTypes => ({
  type: types.Reset,
})

export const tick = (): types.PlayActionTypes => ({
  type: types.Tick,
})

export const $tick =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    dispatcher(tick())
    await dispatcher($saveGame())
  }

export const recoverOneLifeWithAds = (): types.PlayActionTypes => ({
  type: types.RecoverOneLifeWithAds,
})

export const $recoverOneLifeWithAds =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    dispatcher(recoverOneLifeWithAds())
    dispatcher($saveGame())
  }

export const $recoverOneLifeWithCoins =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di, coins, auth } = getState()

    if (!auth.user?.id) return false
    if (coins.amount <= 0) return false

    di.AnalyticsService.send({
      category: "coins",
      action: "spend-for-heart",
    })

    dispatcher(actions.coins.$spend(1))
    dispatcher(recoverOneLifeWithAds())
    dispatcher($saveGame())
  }

export const pause = (): types.PlayActionTypes => ({
  type: types.Pause,
})

export const resume = (): types.PlayActionTypes => ({
  type: types.Resume,
})

export const undo = (): types.PlayActionTypes => ({
  type: types.Undo,
})

export const $undo =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    dispatcher(undo())
    dispatcher($saveGame())
  }

export const up = (): types.PlayActionTypes => ({
  type: types.Up,
})

export const down = (): types.PlayActionTypes => ({
  type: types.Down,
})

export const left = (): types.PlayActionTypes => ({
  type: types.Left,
})

export const toggleErrors = (): types.PlayActionTypes => ({
  type: types.ToggleErrors,
})

export const right = (): types.PlayActionTypes => ({
  type: types.Right,
})

export const changeDraft = (
  payload: types.ChangeDraftAction["payload"],
): types.PlayActionTypes => ({
  type: types.ChangeDraft,
  payload,
})

export const $changeDraft =
  (
    payload: types.ChangeDraftAction["payload"],
  ): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    dispatcher(changeDraft(payload))
    dispatcher($saveGame())
  }

export const toggleDraftMode = (): types.PlayActionTypes => ({
  type: types.ToggleDraftMode,
})

export const changeCel = (
  payload: types.ChangeCelAction["payload"],
): types.PlayActionTypes => ({
  type: types.ChangeCel,
  payload,
})

export const $changeCel =
  (
    payload: types.ChangeCelAction["payload"],
  ): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    dispatcher(changeCel(payload))
    await dispatcher($saveGame())
    await dispatcher($saveStatsIfWin())
  }

export const $changeCelOrDraft =
  (
    payload: types.ChangeCelAction["payload"],
  ): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { play } = getState()
    if (play.draftMode) return dispatcher($changeDraft(payload))
    return dispatcher($changeCel(payload))
  }

export const showClue = (): types.PlayActionTypes => ({
  type: types.ShowClue,
})

export const $showClue =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    dispatcher(showClue())
    dispatcher($saveGame())
    dispatcher($saveStatsIfWin())
  }

export const storeHistory = (
  payload: types.StoreHistoryAction["payload"],
): types.PlayActionTypes => ({
  type: types.StoreHistory,
  payload,
})

export const storeGames = (
  payload: types.StoreGamesAction["payload"],
): types.PlayActionTypes => ({
  type: types.StoreGames,
  payload,
})

export const storeChallenges = (
  payload: types.StoreChallengesAction["payload"],
): types.PlayActionTypes => ({
  type: types.StoreChallenges,
  payload,
})

export const fetching = (): types.PlayActionTypes => ({
  type: types.Fetching,
})

export const fetchEnd = (): types.PlayActionTypes => ({
  type: types.FetchEnd,
})

export const storeGameAndContinuePlaying = (
  payload: types.StoreGameAndContinuePlayingAction["payload"],
): types.PlayActionTypes => ({
  type: types.StoreGameAndContinuePlaying,
  payload,
})

export const checkLocalStorageAndSelectGame =
  (params: { level: string }): ThunkAction<any, RootState, any, any> =>
  async (dispatcher, getState) => {
    const { di, marketplace, pricing } = getState()

    const game = await di.PlayRepository.load(`sudoku/playing/${params.level}`)

    if (game && game.version === VERSION)
      return dispatcher(storeGameAndContinuePlaying({ game }))

    return dispatcher(
      selectGame({
        lifePowerUp:
          marketplace.items.has(HEARTS_ONE_DAY_POWER_UP) || pricing.isPremium,
      }),
    )
  }

export const $removeAllGamesFromLocalStorage =
  (): ThunkAction<any, RootState, any, any> => async (dispatcher, getState) => {
    const { di } = getState()

    for (const level of LEVELS) {
      di.PlayRepository.remove(`sudoku/playing/${level}`)
    }
  }
